import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const LOGOS = [
  {
    src: '/clients/welovedogs.png',
    alt: 'We Love Dogs',
    href: 'https://www.welovedogs.cz/',
  },
  {
    src: '/clients/divesoft.png',
    alt: 'Divesoft',
    href: 'https://www.divesoft.com/',
  },
  {
    src: '/clients/promedeus.png',
    alt: 'Promedeus',
    href: 'https://www.promedeus.cz/',
  },
  {
    src: '/clients/kaprdivers.png',
    alt: 'Kapr Divers',
    href: 'hhttps://www.kaprdivers.cz/',
  },
  {
    src: '/clients/secondbrand.png',
    alt: 'Second Brand',
    href: 'https://secondbrand.cz/',
  },
  {
    src: '/clients/alzbetaprotivanska.png',
    alt: 'Alžběta Protivanská',
    href: 'https://alzbetaprotivanska.cz/',
  },
  {
    src: '/clients/ctp.png',
    alt: 'CTP',
    href: 'https://www.ctp.eu/',
  },
  {
    src: '/clients/cyanplanet.png',
    alt: 'Cyan Planet',
    href: 'https://www.cyanplanet.org/',
  },
  {
    src: '/clients/rastenberger.png',
    alt: 'Rastenberger',
    href: 'http://www.rastenberger.de/',
  },
  {
    src: '/clients/liteasolutions.png',
    alt: 'Litea Solutions',
    href: 'https://www.litea.cz/',
  },
];

const Services = () => (
  <StaticQuery
    query={graphql`
      query {
        grow: file(sourceInstanceName: { eq: "art" }, name: { eq: "1_grow" }) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        plant: file(sourceInstanceName: { eq: "art" }, name: { eq: "2_plant" }) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        kontakt: file(sourceInstanceName: { eq: "art" }, name: { eq: "7_kontakt" }) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => {
      const { t } = useTranslation('services');

      return (
        <Section id="services">
          <Container>
            <h2>Služby</h2>
            <Grid>
              <div>
                <p>
                  Pomáhám firmám s marketingem. Nastavím strategii, část navazujících úkolů zpracuji
                  sama, část úkolů předám specialistovi na danou oblast. Nejlépe pracuju dlouhodobě 
                  pro středně velké firmy a segment STEM.
                </p>
                <br />
                <p>
                  Zjistím, jaká vaše firma je a jakou značku chcete budovat. Poznám vaše zákazníky a
                  najdu další skupiny k oslovení. Na&nbsp;začátku si tak dáme společný workshop, kde
                  si ujasníme dlouhodobý směr.
                </p>
              </div>
              <Art>
                <Img fluid={data.grow.childImageSharp.fluid} />
              </Art>
            </Grid>
            <Grid inverse>
              <Art>
                <Img fluid={data.plant.childImageSharp.fluid} />
              </Art>
              <div>
                <p>
                  Vyberu místa, kde se potenciální zákazníci pohybují, a&nbsp;vymyslím sdělení,
                  které jim předat. Nejčastěji tak tvořím plán a obsah pro
                </p>
                <ul>
                  <li>web,</li>
                  <li>newslettery,</li>
                  <li>sociální média,</li>
                  <li>blog,</li>
                  <li>krátkodobé i dlouhodobé kampaně.</li>
                </ul>
              </div>
            </Grid>
            <Grid>
              <div>
                <p>
                  Kontroluji a zpětně vyhodnocuji výsledky. Jsem nadšenec do&nbsp;tabulek a grafů.
                  Ráda inovuji a neustále hledám cesty,&nbsp;jak&nbsp;v&nbsp;komunikaci značky
                  odlišit, aby si je lidé zapamatovali.
                </p>
              </div>
              <Art>
                <Img fluid={data.kontakt.childImageSharp.fluid} />
              </Art>
            </Grid>
          </Container>
          <br />
          <Container>
            <h3>Klienti, kteří už využili mých služeb</h3>
            <br />
            <LogoFlex>
              {LOGOS.map((l) => (
                <Logo key={l.src} href={l.href}>
                  <img src={l.src} alt={l.alt} />
                </Logo>
              ))}
            </LogoFlex>
          </Container>
        </Section>
      );
    }}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: left;
  justify-items: center;
  margin: 24px 0;

  ${(props) =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  ul,
  li {
    ${(props) => props.theme.font_size.regular};
    color: ${(props) => props.theme.color.black.light};
    font-family: ${(props) => props.theme.font.primary};
    @media (max-width: ${(props) => props.theme.screen.md}) {
      ${(props) => props.theme.font_size.small};
    }
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 8px;
    text-align: left;

    &:last-child {
      margin-bottom: 24px;
    }

    ${(props) =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const LogoFlex = styled.div`
  display: flex;
  margin-top: 16px;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
`;

const Logo = styled.a`
  margin: 32px;
  vertical-align: center;
  position: relative;
  display: grid;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin: 32px 16px;
  }

  img {
    max-height: 72px;
    max-width: 192px;
    display: inline-block;
    margin: 0 1em;
    flex-grow: 1;
    align-self: center;
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default Services;
