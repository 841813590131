import React from 'react';
import Helmet from 'react-helmet';

const SEO_DATA = {
  title: 'Iveta Montserrat – specialistka na online marketing',
  description:
    'Pomáhám firmám s marketingem. Nastavím strategii a část navazujících úkolů zpracuji sama. Nejlépe pracuji dlouhodobě pro malé a středně velké firmy.',
  url: 'https://www.ivetaorsulikova.cz',
  author: 'Francesc Montserrat',
  keywords: [
    'Iveta Oršulíková',
    'marketing',
    'online marketing',
    'digitalni marketing',
    'sociální sítě',
  ],
  img: '',
  facebookId: '',
};

const SEO = () => {
  return (
    <Helmet>
      <meta property="fb:app_id" content={SEO_DATA.facebookId} />
      <meta property="og:title" content={SEO_DATA.title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={SEO_DATA.url} />
      <meta property="og:image" content={SEO_DATA.img} />
      <meta property="og:description" content={SEO_DATA.description} />

      <meta name="description" content={SEO_DATA.description} />
      <meta name="keywords" content={SEO_DATA.keywords.join(', ')} />
      <meta name="author" content={SEO_DATA.author} />
      <title>{SEO_DATA.title}</title>
      <html lang="cs" />
    </Helmet>
  );
};

export default SEO;
