import Video from '../common/Video';
import React from 'react';

import { Container, Section } from '@components/global';

import styled from 'styled-components';

const Videos = () => (
  <Section id="video">
    <Container>
      <h2>Video</h2>
      <CardFlex>
        <Video
          title="Lovebrand: jak budovat naši značku"
          url="https://www.youtube.com/watch?v=Gh-6Igyww2I"
        />
      </CardFlex>
    </Container>
  </Section>
);

const CardFlex = styled.div`
  display: flex;
  margin-top: 48px;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-top: 16px;
  }
`;

export default Videos;
