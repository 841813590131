import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';

import Header from '@sections/Header';


import Services from '@sections/Services';
import About from '@sections/About';
import References from '@sections/References';
import Articles from '@sections/Articles';
import Videos from '@sections/Videos';
import Faq from '@sections/Faq';
import Footer from '@sections/Footer';
import Contact from '@sections/Contact';

const IndexPage = (props) => (
  <Layout>
      <Navbar language={props.language}/>
      <Header language={props.language}/>
      <Services language={props.language}/>
      <References language={props.language}/>
      <About language={props.language}/>
      <Articles language={props.language}/>
      <Videos language={props.language}/>
      <Faq language={props.language}/>
      <Contact language={props.language}/>
      <Footer language={props.language}/>
  </Layout>
);

export default IndexPage;
