import ReferenceCard from '../common/ReferenceCard';
import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';

import { Container, Section } from '@components/global';

const REFERENCES = [
  {
    text: [
      'Iveta si nás získala hned při prvním společném setkání na téma strategie značky Divesoft. To byl prosinec 2019, máme spolu za sebou spoustu spokojených měsíců.',
      'Její práce nyní už zcela přesahuje původně stanovené hranice, podílí se na tvorbě social obsahu, na plánování a realizaci kampaní, na komunikaci s ambasadory i zákazníky, je nedílnou součástí našeho teamu.',
      "Oproti 'klasické' agentuře, která nás v minulosti brala jen jako platícího zákazníka, tak Ivet se potápí, takže skvěle chápe prostředí, které je pro některé lidi ne úplně dobře pochopitelné.",
      'Služby Ivety ale nikomu rozhodně nedoporučím chceme její čas využívat na maximální maximum a nechceme se o ni dělit.',
    ],
    author: 'Dušan Urban',
    companies: ['Divesoft'],
    companyLinks: ['https://www.divesoft.com/'],
    picture: '/pictures/dusan.jpeg',
    role: '',
  },
  {
    text: [
      'O tom, že Iveta své práci vždy dává nějakou přidanou hodnotu, jsem se přesvědčila několikrát v kooperaci s&nbsp;ní a menšími či většími klienty. Její přístup je profesionální, což však v&nbsp;této branži nepovažujte za&nbsp;samozřejmost. Jiní konzultanti často hezky mluví, ale neumí spolupráci dát jasné obrysy. Práce s&nbsp;Ivetou je srozumitelná, efektivní, profesionální a s lidským rozměrem. A co navíc, dost často se s ní zasmějete.',
      'Iveta si díky své dlouholeté zkušenosti může vybírat projekty, kterým věří a i díky tomu tak může být neustále nabitá a plná chuti do&nbsp;práce s lidmi, která ji stále baví. V&nbsp;tuhle chvíli asi závidíte, ale Iveta je opravdicky šťastný člověk a tak i&nbsp;práce s ní vás "nakope" tím správným směrem.',
    ],
    author: 'Lenka Tyrpeklová',
    companies: ['She&Co', 'SecondBrand'],
    companyLinks: ['http://sheco.cz/', 'https://secondbrand.cz/'],
    picture: '/pictures/lenka.jpeg',
    role: '',
  },
  {
    text: [
      'Od spolupráce s Ivetou jsem si sliboval analýzu naší současné situace, volbu správné marketingové strategie i jejích nástrojů a&nbsp;samozřejmě realizaci.',
      'Jsem naprosto spokojen, protože se mohu starat o to, čemu rozumím a&nbsp;vím, že se mi někdo profesionálně postará o to, čemu rozumí ona a já ne.',
      'Ivetinu odbornost, přehled i milou formu komunikace doporučuji všem, kteří chtějí pomoci s budováním značky a využitím různých forem komunikačních kanálů s&nbsp;potenciálními klienty. Oceňuji i&nbsp;jasnou formu prezentace výsledků.',
    ],
    author: 'Petr Slezák',
    companies: ['Kapr Divers'],
    companyLinks: ['https://www.kaprdivers.cz/'],
    picture: '/pictures/petr.jpeg',
    role: '',
  },
  {
    text: [
      'Spolupráce s Ivetou byla od&nbsp;začátku úplným požehnáním. Pomohla mi zorientovat se v&nbsp;metrikách, marketingových pravidlech i misi mojí značky.',
      'Velmi cením její ochotu se pružně domlouvat o&nbsp;podmínkách spolupráce.',
      'Ivetiny služby doporučuji online podnikatelům i malým podnikům, kteří si přejí marketing zvládat opravdu s&nbsp;nadhledem.',
    ],
    author: 'Alžběta Protivanská',
    companies: ['alzbetaprotivanska.cz'],
    companyLinks: ['https://alzbetaprotivanska.cz/'],
    picture: '/pictures/alzbeta.jpeg',
    role: '',
  },
  {
    text: [
      'Ivetina práce v sobě snoubí vzácnou kombinaci struktury, kreativity a&nbsp;empatie ke klientům. Myslí jí to v&nbsp;číslech i obrazech, dokáže naslouchat a přesto směřovat k&nbsp;vytyčenému cíli konzultace či jiné práce. Vždy chce být klientům především užitečná, proto se snaží ovlivnit zadání tak, aby dávalo co&nbsp;největší smysl a neprosazuje nápady jen proto, že jsou její. Jako kolegyně v ní mám naprostou důvěru, že ať se společně vrhneme do jakékoli práce, výsledek bude smysluplný.',
    ],
    author: 'Zuzana Vyhnánková',
    companies: ['Mezonit', 'Kariérní poradenství pro marketéry'],
    companyLinks: ['https://mezonit.com/', 'https://www.zuzavyhnankova.cz/'],
    picture: '/pictures/zuzka.jpeg',
    role: '',
  },
  {
    text: [
      'Projektem auditu on-line aktivit naší společnosti započala (původně neplánovaná) dlouhodobá spolupráce, která nám pomáhá bez&nbsp;tápání efektivně a srozumitelně komunikovat s našimi klienty, získávat a analyzovat zpětnou vazbu a bez prodlevy reflektovat vývoj v&nbsp;našem segmentu.',
      'Kromě toho IO koordinuje aktivity našich specialistů. Oceňujeme nejen všeobecný přehled a rozsáhlé kontakty, kterými disponuje, ale i její nasazení a schopnost být srozumitelným a empatickým mediátorem.',
    ],
    author: 'Martin Gora',
    companies: ['We love dogs'],
    companyLinks: ['https://www.welovedogs.cz/'],
    picture: '/pictures/gora.jpeg',
    role: '',
  },
];

const References = () => (
  <StaticQuery
    query={graphql`
      query {
        art_story: file(sourceInstanceName: { eq: "art" }, name: { eq: "tell_story" }) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => (
      <Section id="references" accent>
        <Container>
          <div>
            <H2>Reference</H2>
            <CardFlex>
              {REFERENCES.map((r) => (
                <ReferenceCard
                  key={r.text}
                  text={r.text}
                  author={r.author}
                  companies={r.companies}
                  companyLinks={r.companyLinks}
                  position={r.position}
                  picture={r.picture}
                  role={r.role}
                />
              ))}
            </CardFlex>
          </div>
        </Container>
      </Section>
    )}
  />
);

const H2 = styled.h2`
  color: ${(props) => props.theme.color.white.regular};
`;

const CardFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export default References;
