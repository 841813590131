import React from 'react';
import './ReferenceCard.css';
import styled from 'styled-components';
import ExternalLink from '@common/ExternalLink';

type ReferenceCardProps = {
  text: string[];
  author: string;
  companies: string[];
  companyLinks: string[];
  description?: string;
  picture?: string;
  role: string;
};

export default (props: ReferenceCardProps) => (
  <>
    <Card>
      <QuoteContainer>
        <QuoteContainer>
          <q className="quote">
            {props.text.map((line: string) => (
              <Paragraph dangerouslySetInnerHTML={{ __html: line }} key={line} />
            ))}
          </q>
        </QuoteContainer>
        <ImageContainer>
          {props.picture && <StyledImg src={props.picture} />}
          <div>
            <Author>{props.author}</Author>
            <Position>
              {props.companies.map((c: string, index: number) => (
                <ExternalLink href={props.companyLinks[index]} key={props.companyLinks[index]}>
                  <div key={c}>{c}</div>
                </ExternalLink>
              ))}
            </Position>
          </div>
        </ImageContainer>
      </QuoteContainer>
    </Card>
  </>
);

const Author = styled.div`
  color: ${(props) => props.theme.color.black.light};
  text-align: left;
  margin-left: 0.5em;
  margin-bottom: 0.5em;
`;

const Position = styled.div`
  color: ${(props) => props.theme.color.black.lighter};
  text-align: left;
  margin-left: 0.5em;
  font-size: 14px;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 16px;

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    margin: 0 30%;
    flex-direction: row;
    
    div {
      display: flex;
      flex-direction: column;
    }
`;

const StyledImg = styled.img`
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin-bottom: 12px;

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    height: 80px;
    width: 80px;
    margin-right: 8px;
  }
`;

const QuoteContainer = styled.div`
  position: relative;
  display: flex;

  @media (max-width: ${(props) => props.theme.screen.xs}) {
    flex-direction: column;
  }
`;

const Paragraph = styled.div`
  margin-bottom: 12px;
`;

const Card = styled.div`
  position: relative;
  background-color: white;
  min-height: 200px;
  width: 520px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  margin: 32px;
  border-radius: 2%;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin: 16px;
  }
`;
