import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';

import './Video.css';

type VideoProps = {
  title: string;
  url: string;
};

export default (props: VideoProps) => (
  <Container>
    <ReactPlayer className="player" url={props.url} width="100%" height="100%" />
  </Container>
);

const Container = styled.div`
  margin: 32px 0;
  width: 80%;
  position: relative;
  padding-top: 40%;
`;
