import React from 'react';
import styled from 'styled-components';

import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

import LinkedInIcon from '@static/icons/linkedin.svg';
import InstagramIcon from '@static/icons/instagram.svg';
import WhatsappIcon from '@static/icons/whatsapp.svg';
import PinIcon from '@static/icons/pin.svg';

const SOCIAL = [
  {
    icon: LinkedInIcon,
    link: 'https://www.linkedin.com/in/iveta-orsulikova/',
  },
  {
    icon: WhatsappIcon,
    link: 'https://api.whatsapp.com/send?phone=420733120770',
  },
  {
    icon: InstagramIcon,
    link: 'https://www.instagram.com/watarine/',
  },
];

const Footer = () => (
  <React.Fragment>
    <FooterWrapper>
      <StyledContainer>
        <Copyright>
          <FooterText>
            2021 © <img width={32} height={32} src={PinIcon} alt="location" /> Předboj
          </FooterText>
        </Copyright>
        <SocialIcons>
          <FooterLink>
            <ExternalLink href="https://api.whatsapp.com/send?phone=420733120770">
              +420 733 120 770
            </ExternalLink>
          </FooterLink>
          <FooterLink>
            <ExternalLink href="mailo:iveta@orsulikova.cz">iveta@orsulikova.cz</ExternalLink>
          </FooterLink>
          {SOCIAL.map(({ icon, link }) => (
            <ExternalLink key={link} href={link}>
              <img src={icon} alt="link" />
            </ExternalLink>
          ))}
        </SocialIcons>
      </StyledContainer>
    </FooterWrapper>
  </React.Fragment>
);

const SocialIcons = styled.div`
  display: flex;

  img {
    margin: 0 8px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-top: 40px;
    display: inline-block;
  }
`;

const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.color.primary};
  padding: 32px 0;
`;

const Copyright = styled.div`
  font-family: ${(props) => props.theme.font.secondary};
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.black.regular};

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

const FooterText = styled.div`
  align-items: center;
  font-family: ${(props) => props.theme.font.primary};
  ${(props) => props.theme.font_size.small};
  color: ${(props) => props.theme.color.white.regular};
`;

const FooterLink = styled.div`
  ${(props) => props.theme.font_size.small};

  a {
    color: ${(props) => props.theme.color.white.regular};
    margin: 0 8px;
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    padding-bottom: 12px;
    margin: 0;
  }
`;

export default Footer;
