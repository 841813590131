import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Section } from '@components/global';
import styled from 'styled-components';

const Contact = (props) => {
  const { t } = useTranslation('header');

  return (
    <Section id="contact">
      <Container>
        <h2>Kontakt</h2>
        <FormContainer>
          <Form
            method="post"
            target="_blank"
            netlify-honeypot="bot-field"
            data-netlify="true"
            name="contact"
            action="https://getform.io/f/9603f20f-b324-49d5-a316-8f4ecc955a58"
          >
            <p>
              Pokud máte zájem o spolupráci, nebo se chcete na cokoliv zeptat, stačí vyplnit
              formulář. V patičce najdete i&nbsp;další kontakty, kde mě zastihnete.
            </p>
            <Input required={true} placeholder=" Vaše jméno" type="text" name="name" />
            <Input required={true} placeholder=" vasemail@domena.cz" type="email" name="email" />
            <Message required={true} placeholder=" Vaše zpráva." type="text" name="message" />
            <Send type="submit">Odeslat</Send>
          </Form>
        </FormContainer>
      </Container>
    </Section>
  );
};

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-top: 48px;
`;

const Form = styled.form`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  width: 80%;
  margin-top: 24px;
  height: 40px;
  padding-left: 8px;
  border-color: rgb(171, 168, 175) !important;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 100%;
    text-align: left;
    font-size: 16px;
  }
`;

const Message = styled.textarea`
  width: 80%;
  height: 300px;
  margin-top: 24px;
  padding-top: 12px;
  padding-left: 8px;
  border-color: rgb(171, 168, 175) !important;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 100%;
    font-size: 16px;
  }
`;

const Send = styled.button`
  background-color: ${(props) => props.theme.color.secondary};
  color: ${(props) => props.theme.color.white.regular};
  margin-top: 48px;
  width: 20%;
  border-radius: 8px;
  height: 60px;
  min-width: 100px;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin: 16px auto 0 auto;
  }
`;
export default Contact;
