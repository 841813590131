import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import ExternalLink from '@common/ExternalLink';
import _ from 'lodash';

type ArticleProps = {
  topic: string;
  title: string;
  picture: string;
  date: Date;
  url: string;
  media: string;
};

const Article = (props: ArticleProps) => (
  <StaticQuery
    query={graphql`
      query {
        lego: file(sourceInstanceName: { eq: "art" }, name: { eq: "lego" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        egg: file(sourceInstanceName: { eq: "art" }, name: { eq: "egg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        phone: file(sourceInstanceName: { eq: "art" }, name: { eq: "phone" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        cake: file(sourceInstanceName: { eq: "art" }, name: { eq: "cake" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        cakes: file(sourceInstanceName: { eq: "art" }, name: { eq: "cakes" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        iveta: file(sourceInstanceName: { eq: "art" }, name: { eq: "iveta" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        vanoce: file(sourceInstanceName: { eq: "art" }, name: { eq: "vanoce" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => (
      <Container>
        <Image src={`/icons/${props.media}.png`} />
        <StyledExternalLink href={props.url}>
          <div>
            <Art>
              <Img fluid={_.get(data, `${props.picture}.childImageSharp.fluid`)} />
            </Art>
            <Title className="title">{props.title}</Title>
          </div>
        </StyledExternalLink>
      </Container>
    )}
  />
);

// const Topic = styled.label`
//   color: palevioletred;
//   font-size: 16px;
//   margin-bottom: 12px;
//   font-family: ${props => props.theme.font.primary};
// `;

const Container = styled.div`
  width: 300px;
  height: 300px;
  margin: 26px 42px;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin: 8px 0;
    height: 280px;
  }
`;

const Art = styled.figure`
  margin: 0 0 16px 0;
  max-width: 380px;
  width: 100%;
  max-height: 140px;
  overflow: hidden;
  border-radius: 4px;
`;

const StyledExternalLink = styled(ExternalLink)`
  color: ${(props) => props.theme.color.white.regular};
`;

const Title = styled.h3`
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    ${(props) => props.theme.font_size.small};
  }
`;

const Image = styled.img`
  height: 26px;
  margin-bottom: 8px;
`;

export default Article;
