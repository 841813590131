import ExternalLink from '../common/ExternalLink';
import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        read: file(sourceInstanceName: { eq: "art" }, name: { eq: "3_read" }) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        phone: file(sourceInstanceName: { eq: "art" }, name: { eq: "4_phone" }) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => (
      <Section id="about me">
        <Container>
          <h2>Kdo jsem</h2>
          <Grid>
            <div>
              <p>Jsem konzultantka i specialistka v jednom.</p>
              <br />
              <p>
                Ráda věci naplánuji, ale chci zasahovat i do jejich uvádění do&nbsp;praxe. Rychle
                prototypuji a baví mě nimrat se v detailech. Pracuji externě, ale udržuji úzké
                vztahy s týmem klienta.
              </p>
              <br />
            </div>
            <Art>
              <Img fluid={data.read.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Img fluid={data.phone.childImageSharp.fluid} />
            </Art>
            <div>
              <p>
                Byla jsem partnerem a konzultantkou v{' '}
                <ExternalLink href="http://www.lovebrand.cz/">Lovebrandu</ExternalLink>, social
                media team leadream a strategistkou v agentuře Sun Marketing (dnes Taste) a
                marketingovou manažerkou v Ladyboxu.
              </p>
              <br />
              <p>
                Na volné noze mi to vyhovuje nejvíc, protože tak dělám různorodou práci, můžu si
                klienty hýčkat.
              </p>
            </div>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${(props) =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;
    grid-gap: 8px;
    margin: 8px 0;

    &:last-child {
      margin-bottom: 24px;
    }

    ${(props) =>
    props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;
