import Article from '../common/Article';
import React from 'react';

import { Container, Section } from '@components/global';

import styled from 'styled-components';

const ARTICLES = [
  {
    topic: 'brand management',
    media: 'darujemeceskevanoce',
    title: 'První 4 kroky k úspěšné tvůrčí značce',
    picture: 'vanoce',
    url: 'https://www.darujemeceskevanoce.cz/2020/11/prvni-4-kroky-k-uspesne-tvurci-znacce/',
    date: new Date('2020-11-23'),
  },
  {
    topic: 'brand management',
    media: 'digichef',
    title: 'Praktické tipy, jak přežít rebranding značky',
    picture: 'cake',
    url: 'https://digichef.cz/prakticke-tipy-jak-prezit-rebranding-znacky/',
    date: new Date('2020-01-21'),
  },
  {
    topic: 'brand management',
    media: 'digichef',
    title: 'B2B firma strategii značky nepotřebuje. Nebo potřebuje?',
    picture: 'cakes',
    url: 'https://digichef.cz/b2b-firma-strategii-znacky-nepotrebuje-nebo-potrebuje',
    date: new Date('2019-08-27'),
  },
  {
    topic: 'brand management',
    media: 'forbes',
    title: 'Udělejte si pořádek v zákaznících. Pomůžou vám segmenty',
    picture: 'lego',
    url: 'https://www.forbes.cz/udelejte-si-poradek-v-zakaznicich-pomuzou-vam-segmenty',
    date: new Date('2019-01-24'),
  },
  {
    topic: 'brand management',
    media: 'forbes',
    title: 'Budování úspěšné značky leží hlavně na zaměstnancích. Jak je nadchnout?',
    picture: 'egg',
    url:
      'https://www.forbes.cz/budovani-uspesne-znacky-lezi-hlavne-na-zamestnancich-jak-je-nadchnout/',
    date: new Date('2018-10-18'),
  },
  {
    topic: 'brand management',
    media: 'forbes',
    title: 'Nevíte, jak vymýšlet obsah na Facebook? Nastavte si obsahové pilíře',
    picture: 'phone',
    url: 'https://www.forbes.cz/nevite-jak-vymyslet-obsah-na-facebook-nastavte-si-obsahove-pilire/',
    date: new Date('2018-09-25'),
  },
  {
    topic: 'brand management',
    media: 'mam',
    title: 'KOMENTÁŘ: Promyslet architekturu značek si nezaslouží jen velké firmy',
    picture: 'iveta',
    url:
      'https://mam.cz/nazory/2020-04/komentar-promyslet-architekturu-znacek-si-nezaslouzi-jen-velke-firmy/',
    date: new Date('2020-04-09'),
  },
  {
    topic: 'brand management',
    media: 'tyden',
    title: 'Značky, které pozitivně ovlivňují okolí, nepoznáte podle sloganu',
    picture: 'iveta',
    url:
      'https://www.tyden.cz/rubriky/lide/znacky-ktere-pozitivne-ovlivnuji-okoli-nepoznate-podle-sloganu_487281.html',
    date: new Date('2018-07-03'),
  },
];

const Articles = () => (
  <Section id="articles" accent>
    <Container>
      <H2>Články</H2>
      <CardFlex>
        {ARTICLES.sort((a, b) => (a.date > b.date ? -1 : 0)).map((a) => (
          <Article
            key={a.title}
            media={a.media}
            title={a.title}
            picture={a.picture}
            url={a.url}
            date={a.date}
            topic={a.topic}
          />
        ))}
      </CardFlex>
    </Container>
  </Section>
);

const CardFlex = styled.div`
  display: flex;
  margin-top: 48px;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-top: 16px;
  }
`;

const H2 = styled.h2`
  color: ${(props) => props.theme.color.white.regular};
`;

export default Articles;
