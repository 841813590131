import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Img from 'gatsby-image';
import { Container } from '@components/global';
import styled from 'styled-components';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Header = (props) => (
  <StaticQuery
    query={graphql`
      query {
        art_face: file(sourceInstanceName: { eq: "art" }, name: { eq: "face" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={(data) => {
      const { t } = useTranslation('header');

      return (
        <HeaderWrapper>
          <Container>
            <Grid>
              <Art>
                <StyledImg fluid={data.art_face.childImageSharp.fluid} />
              </Art>
              <Text>
                <H1>
                  {t('line1')}
                  <Br />
                  {t('line2')}
                  <Br />
                  {t('line3')}
                </H1>
                <br />
                <p>
                  <StyledAnchorLink href="#contact">{t('writeMe')} &nbsp;&#x2794;</StyledAnchorLink>
                </p>
              </Text>
            </Grid>
          </Container>
        </HeaderWrapper>
      );
    }}
  />
);

const HeaderWrapper = styled.header`
  background-color: ${(props) => props.theme.color.primary};
  padding-top: 96px;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    padding-top: 64px;
  }
`;

const StyledImg = styled(Img)`
  grid-row: 1 / -1;
  border-radius: 50%;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin: auto;
  }
`;

const Art = styled.figure`
  width: 90%;
  margin: 0;

  > div {
    width: 90%;
    margin-bottom: 10%;
  }

  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin: 32px auto 64px auto;
    padding-left: 8;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 64px;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 20px;

    > ${Art} {
      order: 2;
    }
  }
`;

const Text = styled.div`
  justify-self: center;

  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: start;
  }
`;

const StyledAnchorLink = styled(AnchorLink)`
  color: ${(props) => props.theme.color.black.light};
  text-decoration: none;

  &:hover {
    color: ${(props) => props.theme.color.black.regular};
  }
`;

const Br = styled.br`
  content: '';
  height: 12px;
  display: block;
`;

const H1 = styled.h1`
  color: ${(props) => props.theme.color.white.regular};
`;

export default Header;
