import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';
import ExternalLink from '@common/ExternalLink';
import styled from 'styled-components';

const FAQS = [
  {
    title: 'Kolik vás to bude stát?',
    content: () => (
      <>
        Pro jednorázové zakázky je má hodinkovka 1 600 Kč bez DPH. Pro dlouhodobé klienty je cena nižší a
        záleží na rozsahu spolupráce.
      </>
    ),
  },
  {
    title: 'Pro jaké klienty pracuji?',
    content: () => (
      <>
        Hlavní pro mě je klientův zápal pro věc a snaha dělat věci správně. Mám zkušenosti s
        všelijakými sektory v B2B i v B2C. Vyhýbám se jen firmám, které přeprodávají zboží nebo
        práci bez vlastní přidané hodnoty.
      </>
    ),
  },
  {
    title: 'Jakou formou pracuji?',
    content: () => (
      <>
        Pracuji jen externě, s klienty se ale pravidelně osobně vídám. Přizpůsobuji se v organizaci
        práce a termínech klientovi. Jsem OSVČ a plátce DPH.
      </>
    ),
  },
  {
    title: 'Proč byste si měli vybrat mě?',
    content: () => (
      <>
        Snažím se poznat klientův byznys opravdu dopodrobna a v případě spolupráce se stávám jeho
        největším zastáncem. Pokud&nbsp;se pro to, co děláte, dokážu nadchnout já, nadchnu pro to i
        ostatní lidi. Jsem proti šablonovitým řešením, protože u&nbsp;každého má smysl trochu něco
        jiného. Sleduju za vás, co se děje v oboru i co dělá konkurence. Nečekám, až mě zaúkolujete,
        úkoluji se sama.
      </>
    ),
  },
];

const Faq = () => (
  <Section id="faq" accent>
    <Container>
      <H2 style={{ marginBottom: 40 }}>Otázky a odpovědi</H2>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

const H2 = styled.h2`
  color: ${(props) => props.theme.color.white.regular};
`;

export default Faq;
